// Importing necessary modules and components
import { Fragment } from "react";
import { AppProps } from "next/app";
import { ThemeProvider } from "@zigops-material/react";
import "/styles/globals.css";
import "react-toastify/dist/ReactToastify.css";
import RootLayout from "@/components/layout/rootLayout";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "@/utils/authService";
import { Provider } from "react-redux";
import { persistor, store } from "@/redux/configureStore";
import { PersistGate } from "redux-persist/integration/react";
import { LDProvider } from "launchdarkly-react-client-sdk";

// Component responsible for the main layout and authentication logic
function ZigOpsMain({ Component, pageProps: { session, ...pageProps } }: AppProps) {

  // Rendering the components and providing the required context/providers
  return (
    <Fragment>
      <MsalProvider instance={msalInstance}>
        <LDProvider clientSideID={process.env.REACT_APP_LAUNCH_DARKLY_CLIENT_ID ?? ''}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ThemeProvider>
                <RootLayout>
                  <Component {...pageProps} />
                </RootLayout>
              </ThemeProvider>
            </PersistGate>
          </Provider>
        </LDProvider>
      </MsalProvider>
    </Fragment>
  );
}

// Exporting the main component as the default export
export default ZigOpsMain;
